import dynamic from 'next/dynamic';
import Head from 'next/head';
import { useTranslation } from 'react-i18next';
import { getLoginServerSideProps as getServerSideProps } from '@config/app-server-side-props';
import { APP_PREFIX_TRANSLATION_KEY } from '@constants/app';
import { useGetLocale } from '@hooks/profile';
import LoadingLoginPage from '@features/login/components/loading-login-page';
import { useEffect } from 'react';
import i18n from '@config/i18n';
import { countryCodeToLocale } from '@utils/countryCodeToLocale';

export { getServerSideProps };

const Page = dynamic(() => import('@features/login/page/index'));

export default function LoginPage() {
  const { t } = useTranslation();

  const localeData = useGetLocale();

  useEffect(() => {
    if (localeData?.data) {
      i18n.changeLanguage(countryCodeToLocale(localeData?.data?.country)).then();
    }
  }, [localeData?.data]);

  return (
    <>
      <Head>
        <title>{t(APP_PREFIX_TRANSLATION_KEY + 'sign_in')}</title>
      </Head>
      {localeData?.isLoading ? <LoadingLoginPage /> : <Page />}
    </>
  );
}
